<template>
  <div class="my-mall">
    <div class="book-list" v-for="item in mall">
      <img :src="item.imgUrl">
      <div class="price-name">
        {{$store.state.currentLanguage ? item.name: item.enName}}
      </div>
      <div class="price-c">
        <span class="price-no"> {{$store.state.currentLanguage ? '原价': 'Original'}}：￥{{item.price + '.00元'}}</span>
      </div>
      <div class="btn-content">
        <span class="price-over">￥ {{item.curryPrice + '.00'}}</span>
        <div class="price-btn" @click="buyBookEvr(item.curryPrice)">{{$store.state.currentLanguage ? '购买': 'Buy'}}</div>
      </div>
    </div>


    <div class="main-mask2" v-if="buyBook">
      <div class="mask-content">
        <div class="m-title">
          <div class="close-m4" @click="buyBook=false"></div>
        </div>

        <div class="m-payment">
          <div class="p-tit">
            <div class="tit-lf">
              <div class="wx-ico"></div>
              <div class="tj-ico"></div>
            </div>
            <div class="tit-rt">支付 <span>{{ curryMoney }}</span>元</div>
          </div>
          <div class="qr-code"></div>
          <div class="p-foot">请使用微信扫码付款</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'mall',
  data () {
    return {
      curryMoney: 0,
      buyBook: false,
      mall:[{
        imgUrl:'http://www.readabroad.cn/shopingImg/img11.jpg',
        name: '《爱彼迎传》',
        enName: 'Aibi Yingzhuan',
        price: 39,
        curryPrice: 29,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img12.jpg',
        name: '《别独自用餐》',
        enName: 'Don\'t eat alone',
        price: 58,
        curryPrice: 38,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img13.jpg',
        name: '《财务自由》',
        enName: 'Financial freedom',
        price: 59,
        curryPrice: 29,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img14.jpg',
        name: '《吃东西防病》',
        enName: 'Eat to prevent disease',
        price: 49,
        curryPrice: 39,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img15.jpg',
        name: '《激励与工作行为》',
        enName: 'Motivation and work behavior',
        price: 79,
        curryPrice: 59,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img16.jpg',
        name: '《提问的力量》',
        enName: 'The power of questioning',
        price: 88,
        curryPrice: 58,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img17.jpg',
        name: '《效率12周》',
        enName: 'Efficiency 12 weeks',
        price: 66,
        curryPrice: 49,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img18.jpeg',
        name: '《幸福的婚姻》',
        enName: 'Happy marriage',
        price: 48,
        curryPrice: 28,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img19.jpg',
        name: '《一心专用工作法》',
        enName: 'Special working method',
        price: 65,
        curryPrice: 49,
      },{
        imgUrl:'http://www.readabroad.cn/shopingImg/img20.jpeg',
        name: '《总觉得饿》',
        enName: 'I always feel hungry',
        price: 58,
        curryPrice: 49,
      }]
    }
  },
  methods: {
    buyBookEvr(curryPrice){
      this.curryMoney = curryPrice
      this.buyBook = true
    }
  },
  created () {}
}
</script>
